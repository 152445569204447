/*
 * Skin: Black
 * -----------
 */
/* skin-black navbar */
.skin-black .main-header {
  -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
}
.skin-black .main-header .navbar-toggle {
  color: #333;
}
.skin-black .main-header .navbar-brand {
  color: #333;
  border-right: 1px solid #eee;
}
.skin-black .main-header > .navbar {
  background-color: #fff;
}
.skin-black .main-header > .navbar .nav > li > a {
  color: #333;
}
.skin-black .main-header > .navbar .nav > li > a:hover,
.skin-black .main-header > .navbar .nav > li > a:active,
.skin-black .main-header > .navbar .nav > li > a:focus,
.skin-black .main-header > .navbar .nav .open > a,
.skin-black .main-header > .navbar .nav .open > a:hover,
.skin-black .main-header > .navbar .nav .open > a:focus,
.skin-black .main-header > .navbar .nav > .active > a {
  background: #fff;
  color: #999;
}
.skin-black .main-header > .navbar .sidebar-toggle {
  color: #333;
}
.skin-black .main-header > .navbar .sidebar-toggle:hover {
  color: #999;
  background: #fff;
}
.skin-black .main-header > .navbar > .sidebar-toggle {
  color: #333;
  border-right: 1px solid #eee;
}
.skin-black .main-header > .navbar .navbar-nav > li > a {
  border-right: 1px solid #eee;
}
.skin-black .main-header > .navbar .navbar-custom-menu .navbar-nav > li > a,
.skin-black .main-header > .navbar .navbar-right > li > a {
  border-left: 1px solid #a4d4be;
  border-right-width: 0;
}
.skin-black .main-header > .navbar {
  border-bottom: 1px solid #00a9aa;
  box-shadow: 1px 1px 2px #00a9aa;
  background: #e1e1e8;
}
.skin-black .main-header > .logo {
  background-color: #fff;
  color: #333;
  border-bottom: 0 solid transparent;
  border-right: 1px solid #eee;
  background: #e1e1e8;
}
.skin-black .main-header > .logo:hover {
  background-color: #fcfcfc;
}
@media (max-width: 767px) {
  .skin-black .main-header > .logo {
    background-color: #222;
    color: #fff;
    border-bottom: 0 solid transparent;
    border-right: none;
  }
  .skin-black .main-header > .logo:hover {
    background-color: #1f1f1f;
  }
}
.skin-black .main-header li.user-header {
  background-color: #00a9aa;
}
.skin-black .content-header {
  background: transparent;
  box-shadow: none;
}
.skin-black .wrapper,
.skin-black .main-sidebar,
.skin-black .left-side {
  background-color: #00a9aa;
}
.skin-black .user-panel > .info,
.skin-black .user-panel > .info > a {
  color: #fff;
}
.skin-black .sidebar-menu > li.header {
  color: #11feff;
  background: #009596;
}
.skin-black .sidebar-menu > li > a {
  border-left: 3px solid transparent;
}
.skin-black .sidebar-menu > li:hover > a,
.skin-black .sidebar-menu > li.active > a {
  color: #fff;
  background: #007677;
  border-left-color: #fff;
}
.skin-black .sidebar-menu > li > .treeview-menu {
  margin: 0 1px;
  background: #007677;
}
.skin-black .sidebar a {
  color: #ddffff;
}
.skin-black .sidebar a:hover {
  text-decoration: none;
}
.skin-black .treeview-menu > li > a {
  color: #000000;
}
.skin-black .treeview-menu > li.active > a,
.skin-black .treeview-menu > li > a:hover {
  color: #000;
}
.skin-black .sidebar-form {
  border-radius: 3px;
  border: 1px solid #00dcdd;
  margin: 10px 10px;
}
.skin-black .sidebar-form input[type="text"],
.skin-black .sidebar-form .btn {
  box-shadow: none;
  background-color: #00dcdd;
  border: 1px solid transparent;
  height: 35px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.skin-black .sidebar-form input[type="text"] {
  color: #666;
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
}
.skin-black .sidebar-form input[type="text"]:focus,
.skin-black .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  background-color: #fff;
  color: #666;
}
.skin-black .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  border-left-color: #fff;
}
.skin-black .sidebar-form .btn {
  color: #999;
  border-top-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
}
.skin-black .pace .pace-progress {
  background: #222;
}
.skin-black .pace .pace-activity {
  border-top-color: #222;
  border-left-color: #222;
}
